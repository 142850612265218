<template>
    <div>
        <Modal
            v-model="show"
            @on-cancel="onCanel"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            fullscreen
            :z-index="1022"
            :title="$t('previewPdf')"
        >
            <div class="demo-spin-article">
                <div id="container">
                    <iframe :src="src" v-if="!loading"></iframe>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </Modal>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
export default {
  name: 'pdfPreview',
  props: ['value', 'contract_id'],
  data () {
    return {
      show: false,
      loading: false,
      src: ''
    }
  },
  methods: {
    ...mapActions('humanResource/employee', ['previewPdf']),
    onCanel () {
      this.src = ''
      this.$emit('cancel')
    },
    notice (not) {
      this.$Notice[not.type]({
        title: 'EMPLOYEE PROFILES',
        desc: not.text
      })
    }
  },
  watch: {
    value: function (value) {
      if (value) {
        this.show = value
        this.loading = true
        this.previewPdf({
          contract_id: this.contract_id
        })
          .then(response => {
            this.src = response.url
          })
          .catch(error => {
            this.notice({ type: 'error', text: error.message })
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
#container {
    width: 100%;
    height: 90vh;
    background: black;
}
#container iframe {
    width: 100%;
    height: 90vh;
}
</style>
