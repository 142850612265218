<template>
    <div>
        <form class="form-horizontal">
            <h5 class="section-divider">
                {{ $t("employee.employment") }}
            </h5>
            <div class="row">
                <div class="col-md-6">
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label
                                class="form-col-label control-label ts-text-xs"
                                >{{ $t("employee.contractStartDate") }}</label
                            >
                        </div>
                        <div class="col-md-8">
                            <DatePicker
                                style="width: 100%"
                                :value="model.contract_start_date"
                                placeholder="DD-MM-YYYY"
                                format="dd-MM-yyyy"
                                @on-change="onChangeContractStartDate"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label
                                class="form-col-label control-label ts-text-xs"
                                >{{ $t("employee.period") }}</label
                            >
                        </div>
                        <div class="col-md-8">
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Contract Period (month)"
                                v-model="model.contract_period"
                                @input="getExpiryDate"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label
                                class="form-col-label control-label ts-text-xs"
                                >{{ $t("employee.expiredate") }}</label
                            >
                        </div>
                        <div class="col-md-8">
                            <DatePicker
                                :value="model.contract_expire_date"
                                style="width: 100%"
                                placeholder="DD-MM-YYYY"
                                format="dd-MM-yyyy"
                                @on-change="onChangeContractExpiryDate"
                            ></DatePicker>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.resignOrTerminateTerm") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.number="model.resign_or_terminate_term"
                                type="number"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'resign_or_terminate_term'
                                    )
                                }"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.positionName") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.number="model.position_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('position_name')
                                }"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.salaryLevel") }}
                        </label>
                        <div class="col-md-8">
                            <div
                                class="tw-flex tw-items-center tw-justify-between"
                            >
                                <div class="tw-justify-start">
                                    <select
                                        v-model.number="model.level_id"
                                        class="form-select"
                                        :class="{
                                            'is-invalid': errors.has('level_id')
                                        }"
                                        @change="
                                            () => (model.salary_level_id = null)
                                        "
                                    >
                                        <option :value="null">{{
                                            $t("select")
                                        }}</option>
                                        <option
                                            v-for="(value, index) in levels"
                                            :key="index"
                                            :value="value.id"
                                        >
                                            {{ value.name }}
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('level_id')"
                                    >
                                        {{ errors.first("level_id") }}
                                    </div>
                                </div>
                                <div class="tw-justify-end">
                                    <select
                                        v-model.number="model.salary_level_id"
                                        class="form-select"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'salary_level_id'
                                            )
                                        }"
                                    >
                                        <option :value="null">{{
                                            $t("select")
                                        }}</option>
                                        <option
                                            v-for="(value,
                                            index) in salaryLevels"
                                            :key="index"
                                            :value="value.id"
                                        >
                                            {{ value.name }}
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('salary_level_id')"
                                    >
                                        {{ errors.first("salary_level_id") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.marritalStatus") }}
                        </label>
                        <div class="col-md-8">
                            <select
                                v-model.number="model.marrital_status"
                                class="form-select"
                                :class="{
                                    'is-invalid': errors.has('marrital_status')
                                }"
                            >
                                <option :value="null">{{
                                    $t("select")
                                }}</option>
                                <option
                                    v-for="(value, index) in marritalStatus"
                                    :key="index"
                                    :value="value"
                                >
                                    {{ value }}
                                </option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('marrital_status')"
                            >
                                {{ errors.first("marrital_status") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="section-divider">
                {{ $t("employee.employeeGuarantor") }}
            </h5>
            <div class="row">
                <div class="col-md-6">
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.guarantorName") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.trim="model.guarantor_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('guarantor_name')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('guarantor_name')"
                            >
                                {{ errors.first("guarantor_name") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label class="col-md-4">
                            {{ $t("employee.sex") }}
                        </label>
                        <div class="col-md-8">
                            <div class="tw-flex">
                                <div class="tw-justify-start">
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="male"
                                            v-model="model.guarantor_sex"
                                            value="Male"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="male"
                                            >Male</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="female"
                                            v-model="model.guarantor_sex"
                                            value="Female"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="female"
                                            >Female</label
                                        >
                                    </div>
                                </div>
                                <div class="tw-justify-end">
                                    <input
                                        v-model.trim="model.guarantor_age"
                                        :placeholder="
                                            $t('employee.guarantorAge')
                                        "
                                        type="number"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'guarantor_age'
                                            )
                                        }"
                                    />
                                </div>
                            </div>
                            <div
                                class="text-danger"
                                v-if="errors.has('guarantor_sex')"
                            >
                                {{ errors.first("guarantor_sex") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.ssnNo") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.trim="model.guarantor_nid_number"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'guarantor_nid_number'
                                    )
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('guarantor_nid_number')"
                            >
                                {{ errors.first("guarantor_nid_number") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.nationality") }}
                        </label>
                        <div class="col-md-8">
                            <select
                                v-model.number="model.nationality_id"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('nationality_id')
                                }"
                            >
                                <option :value="null">{{
                                    $t("select")
                                }}</option>
                                <option
                                    v-for="(nationality,
                                    index) in nationalities"
                                    :key="index"
                                    :value="nationality.nationality_id"
                                    >{{
                                        nationality.nationality_name_en
                                    }}</option
                                >
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('nationality_id')"
                            >
                                {{ errors.first("nationality_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.spouseName") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.trim="model.spouse_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('spouse_name')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('spouse_name')"
                            >
                                {{ errors.first("spouse_name") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label class="col-md-4">
                            {{ $t("employee.sex") }}
                        </label>
                        <div class="col-md-8">
                            <div class="tw-flex">
                                <div class="tw-justify-start">
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="spouse_male"
                                            v-model="model.spouse_sex"
                                            value="Male"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="spouse_male"
                                            >Male</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="spouse_female"
                                            v-model="model.spouse_sex"
                                            value="Female"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="spouse_female"
                                            >Female</label
                                        >
                                    </div>
                                </div>
                                <div class="tw-justify-end">
                                    <input
                                        v-model.trim="model.spouse_age"
                                        :placeholder="$t('employee.spouseAge')"
                                        type="number"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'spouse_age'
                                            )
                                        }"
                                    />
                                </div>
                            </div>
                            <div
                                class="text-danger"
                                v-if="errors.has('spouse_sex')"
                            >
                                {{ errors.first("spouse_sex") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.ssnNo") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.trim="model.spouse_nid_number"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'spouse_nid_number'
                                    )
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('spouse_nid_number')"
                            >
                                {{ errors.first("spouse_nid_number") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.relationship") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.trim="model.relationship"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('relationship')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('relationship')"
                            >
                                {{ errors.first("relationship") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.province") }}
                        </label>
                        <div class="col-md-8">
                            <select
                                v-model="model.grt_province_code"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'grt_province_code'
                                    )
                                }"
                                @change="
                                    grtProvinceChange(model.grt_province_code)
                                "
                                @input="
                                    model.grt_district_code = model.grt_commune_code = model.grt_village_code = null
                                "
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{ $t("select") }}</option
                                >
                                <option
                                    v-for="(province, index) in provinces"
                                    :key="index"
                                    :value="province.province_code"
                                >
                                    {{ province.name_en }} |
                                    {{ province.name_kh }}
                                </option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('grt_province_code')"
                            >
                                {{ errors.first("grt_province_code") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.district") }}
                        </label>
                        <div class="col-md-8">
                            <ts-loading-banner :loading="grt_province_change">
                                <select
                                    v-model="model.grt_district_code"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'grt_district_code'
                                        )
                                    }"
                                    @change="
                                        grtDistrictChange(
                                            model.grt_district_code
                                        )
                                    "
                                    @input="
                                        model.grt_commune_code = model.grt_village_code = null
                                    "
                                >
                                    <option
                                        :value="null"
                                        disabled
                                        selected
                                        style="display: none;"
                                        >{{ $t("select") }}</option
                                    >
                                    <option
                                        v-for="(district,
                                        index) in grt_districts"
                                        :key="index"
                                        :value="district.district_code"
                                    >
                                        {{ district.name_en }} |
                                        {{ district.name_kh }}
                                    </option>
                                </select>
                            </ts-loading-banner>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('grt_district_code')"
                            >
                                {{ errors.first("grt_district_code") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.commune") }}
                        </label>
                        <div class="col-md-8">
                            <ts-loading-banner :loading="grt_district_change">
                                <select
                                    v-model="model.grt_commune_code"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'grt_commune_code'
                                        )
                                    }"
                                    @change="
                                        grtCommuneChange(model.grt_commune_code)
                                    "
                                    @input="model.grt_village_code = null"
                                >
                                    <option
                                        :value="null"
                                        disabled
                                        selected
                                        style="display: none;"
                                        >{{ $t("select") }}</option
                                    >
                                    <option
                                        v-for="(commune, index) in grt_communes"
                                        :key="index"
                                        :value="commune.commune_code"
                                    >
                                        {{ commune.name_en }} |
                                        {{ commune.name_kh }}
                                    </option>
                                </select>
                            </ts-loading-banner>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('grt_commune_code')"
                            >
                                {{ errors.first("grt_commune_code") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.village") }}
                        </label>
                        <div class="col-md-8">
                            <ts-loading-banner :loading="grt_commune_change">
                                <div class="input-group">
                                    <select
                                        v-model="model.grt_village_code"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'grt_village_code'
                                            )
                                        }"
                                    >
                                        <option
                                            :value="null"
                                            disabled
                                            selected
                                            style="display: none;"
                                            >{{ $t("select") }}</option
                                        >
                                        <option
                                            v-for="(village,
                                            index) in grt_villages"
                                            :key="index"
                                            :value="village.village_code"
                                        >
                                            {{ village.name_en }} |
                                            {{ village.name_kh }}
                                        </option>
                                    </select>
                                    <Poptip
                                        :title="$t('employee.village')"
                                        :transfer="true"
                                        width="240"
                                        @on-popper-show="
                                            () => (name_en = name_kh = '')
                                        "
                                        v-if="
                                            !villageSaving && model.commune_code
                                        "
                                    >
                                        <a
                                            class="btn btn-info"
                                            v-tooltip="$t('add')"
                                            :disabled="villageSaving"
                                        >
                                            <i
                                                class="fas fa-circle-notch fa-spin"
                                                v-if="villageSaving"
                                            ></i>
                                            <i class="fas fa-plus" v-else></i>
                                        </a>
                                        <div slot="content">
                                            <div class="mb-2">
                                                <label class="required">{{
                                                    $t("employee.nameEn")
                                                }}</label>
                                                <input
                                                    v-model.trim="name_en"
                                                    type="text"
                                                    class="form-control"
                                                    :class="{
                                                        'is-invalid': errors.has(
                                                            'name_en'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.has('name_en')"
                                                >
                                                    {{
                                                        errors.first("name_en")
                                                    }}
                                                </div>
                                            </div>
                                            <div class="mb-2">
                                                <label class="required">{{
                                                    $t("employee.nameKh")
                                                }}</label>
                                                <input
                                                    v-model.trim="name_kh"
                                                    type="text"
                                                    class="form-control"
                                                    :class="{
                                                        'is-invalid': errors.has(
                                                            'name_kh'
                                                        )
                                                    }"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.has('name_kh')"
                                                >
                                                    {{
                                                        errors.first("name_kh")
                                                    }}
                                                </div>
                                            </div>
                                            <div class="tw-mt-2 tw-text-right">
                                                <a
                                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                                    @click.prevent="
                                                        onSaveVillage
                                                    "
                                                >
                                                    <span>Save</span>
                                                </a>
                                            </div>
                                        </div>
                                    </Poptip>
                                    <a
                                        class="btn btn-default"
                                        v-tooltip="$t('add')"
                                        :disabled="!model.commune_code"
                                        v-else
                                    >
                                        <i
                                            class="fas fa-circle-notch fa-spin"
                                            v-if="villageSaving"
                                        ></i>
                                        <i class="fas fa-plus" v-else></i>
                                    </a>
                                </div>
                            </ts-loading-banner>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('grt_village_code')"
                            >
                                {{ errors.first("grt_village_code") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="col-md-4">
                            {{ $t("employee.houseNo") }}
                        </label>
                        <div class="col-md-8">
                            <input
                                v-model.trim="model.grt_house_no"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('grt_house_no')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('grt_house_no')"
                            >
                                {{ errors.first("grt_house_no") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    color="info"
                    outline
                    :waiting="update_previewing"
                    :disabled="update_previewing || waiting"
                    @click.prevent="onUpdatePreview"
                >
                    {{ $t("employee.updatePreviewContract") }}</ts-button
                >
                <ts-button
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                    :disabled="waiting || update_previewing"
                    class="btn btn-primary d-block"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </form>
        <Modal
            v-model="show"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            fullscreen
            :z-index="1022"
						@on-cancel="onCanel"
            :title="$t('previewPdf')"
        >
            <div class="demo-spin-article">
                <div id="container">
                    <ts-preview-pdf :src="src" />
                </div>
            </div>
            <Spin size="large" fix v-if="update_previewing"> </Spin>
        </Modal>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";
import moment from "moment";

export default {
    name: "editContractInfo",
    props: ['employees'],
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            update_previewing: false,
            show: false,
            model: {
                contract_id: null,
                employee_id: null,
                resign_or_terminate_term: null,
                contract_start_date: moment().format("DD-MM-YYYY"),
                contract_period: null,
                contract_expire_date: null,
                position_name: null,
                marrital_status: null,
                level_id: null,
                salary_level_id: null,
                guarantor_name: null,
                guarantor_sex: null,
                guarantor_age: null,
                nationality_id: null,
                guarantor_nid_number: null,
                spouse_name: null,
                spouse_sex: null,
                spouse_age: null,
                spouse_nid_number: null,
                relationship: null,
                grt_village_code: null,
                grt_commune_code: null,
                grt_district_code: null,
                grt_province_code: null,
                grt_house_no: null
            },
            grt_province_change: false,
            grt_district_change: false,
            grt_commune_change: false,
            grt_districts: [],
            grt_communes: [],
            grt_villages: [],
            villageSaving: false,
            name_en: null,
            name_kh: null,
            marritalStatus: ["នៅលីវ", "រៀបការ", "មេម៉ាយ", "ពោះម៉ាយ"],
            src: ""
        };
    },
    computed: {
        ...mapState("humanResource/employee", [
            "provinces",
            "levels",
            "salary_levels",
            "nationalities",
            "edit_data"
        ]),
        salaryLevels() {
            if (this.model.level_id) {
                return this.salary_levels.filter(
                    e => e.level_id == this.model.level_id
                );
            }
            return this.salary_levels;
        }
    },
    validations: {
        name_en: { required },
        name_kh: { required },
        villageForm: ["name_en", "name_kh"]
    },
    methods: {
        ...mapActions("humanResource/employee", [
            "getNationality",
            "getLevel",
            "getProvince",
            "getDistrict",
            "getCommune",
            "getVillage"
        ]),
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        async fetchResource() {
            this.errors = new Errors();
            this.loading = true;
            this.clear();
            await this.getNationality();
            await this.getLevel();
            await this.getProvince();
        },
        onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "humanResource/employee/storeContract",
                    Object.assign({}, this.model, {
                        is_save_preview: false
                    })
                )
                .then(response => {
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                    this.clear();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdatePreview() {
            this.errors = new Errors();
            this.update_previewing = true;
            this.$store
                .dispatch(
                    "humanResource/employee/storeContract",
                    Object.assign({}, this.model, {
                        is_save_preview: true
                    })
                )
                .then(response => {
                    this.show = true;
                    this.src = response.url;
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.update_previewing = false;
                });
        },
        async getProvince() {
            await this.$store
                .dispatch("humanResource/employee/getProvince")
                .then(() => {
                    this.setEditData();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        provinceChange(province_code) {
            this.province_change = true;
            this.getDistrict(province_code)
                .then(response => {
                    this.districts = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.province_change = false;
                });
        },

        districtChange(district_code) {
            this.district_change = true;
            this.getCommune(district_code)
                .then(response => {
                    this.communes = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.district_change = false;
                });
        },

        communeChange(commune_code) {
            this.commune_change = true;
            this.getVillage(commune_code)
                .then(response => {
                    this.villages = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.commune_change = false;
                });
        },
        grtProvinceChange(province_code) {
            this.grt_province_change = true;
            this.getDistrict(province_code)
                .then(response => {
                    this.grt_districts = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.grt_province_change = false;
                });
        },

        grtDistrictChange(district_code) {
            this.grt_district_change = true;
            this.getCommune(district_code)
                .then(response => {
                    this.grt_communes = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.grt_district_change = false;
                });
        },

        grtCommuneChange(commune_code) {
            this.grt_commune_change = true;
            this.getVillage(commune_code)
                .then(response => {
                    this.grt_villages = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.grt_commune_change = false;
                });
        },
        async onSaveVillage() {
            this.$v.villageForm.$touch();
            if (this.$v.villageForm.$invalid) return;

            this.villageSaving = true;
            this.$store
                .dispatch("humanResource/employee/addVillage", {
                    name_en: this.name_en,
                    name_kh: this.name_kh,
                    commune_code: this.model.commune_code
                })
                .then(response => {
                    this.villages.push({
                        name_en: response.resource.name_en,
                        name_kh: response.resource.name_kh,
                        village_code: response.resource.village_code
                    });
                    this.model.village_code = response.resource.village_code;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.villageSaving = false;
                    this.name_en = "";
                    this.name_kh = "";
                });
        },
        onChangeContractStartDate(date) {
            this.model.contract_start_date = date;
            this.getExpiryDate;
        },
        getExpiryDate() {
            this.model.contract_expire_date = moment(
                moment(this.model.contract_start_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )
            )
                .add(this.model.contract_period, "months")
                .format("DD-MM-YYYY");
        },
        onChangeContractExpiryDate(date) {
            this.model.contract_expire_date = date;
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.employee_id = this.edit_data.employee_id;

                    this.model.level_id = this.edit_data.level_id;
                    this.model.salary_level_id = this.edit_data.salary_level_id;
                    this.model.marrital_status = this.edit_data.marrital_status;
                    this.model.contract_id = this.edit_data.contract_id;
                    this.model.resign_or_terminate_term = this.edit_data.resign_or_terminate_term;
                    this.model.position_name = this.edit_data.position_name;
                    this.model.contract_start_date = this.edit_data.contract_start_date;
                    this.model.contract_period = this.edit_data.contract_period;
                    this.model.contract_expire_date = this.edit_data.contract_expire_date;

                    if (this.edit_data.grt_province_code) {
                        this.grtProvinceChange(
                            this.edit_data.grt_province_code
                        );
                    }
                    if (this.edit_data.grt_district_code) {
                        this.grtDistrictChange(
                            this.edit_data.grt_district_code
                        );
                    }
                    if (this.edit_data.grt_commune_code) {
                        this.grtCommuneChange(
                            this.edit_data.grt_commune_code
                        );
                    }
                    this.model.grt_province_code = this.edit_data.grt_province_code;
                    this.model.grt_district_code = this.edit_data.grt_district_code;
                    this.model.grt_commune_code = this.edit_data.grt_commune_code;
                    this.model.grt_village_code = this.edit_data.grt_village_code;
                    this.model.guarantor_name = this.edit_data.guarantor_name;
                    this.model.guarantor_sex = this.edit_data.guarantor_sex;
                    this.model.guarantor_age = this.edit_data.guarantor_age;
                    this.model.guarantor_nid_number = this.edit_data.guarantor_nid_number;
                    this.model.nationality_id = this.edit_data.nationality_id;
                    this.model.spouse_nid_number = this.edit_data.spouse_nid_number;
                    this.model.spouse_name = this.edit_data.spouse_name;
                    this.model.spouse_age = this.edit_data.spouse_age;
                    this.model.spouse_sex = this.edit_data.spouse_sex;
                    this.model.relationship = this.edit_data.relationship;
                    this.model.grt_house_no = this.edit_data.grt_house_no;
            }
        },
        clear() {
            this.model = {
                employee_id: null,
                resign_or_terminate_term: null,
                contract_start_date: null,
                contract_period: null,
                contract_expire_date: null,
                position_name: null,
                level_id: null,
                salary_level_id: null,
                guarantor_name: null,
                guarantor_sex: null,
                guarantor_age: null,
                nationality_id: null,
                guarantor_nid_number: null,
                spouse_name: null,
                spouse_sex: null,
                spouse_age: null,
                spouse_nid_number: null,
                relationship: null,
                grt_village_code: null,
                grt_commune_code: null,
                grt_district_code: null,
                grt_province_code: null,
                grt_house_no: null
            };
        },
				onCanel(){
					this.clear();
					this.$emit("cancel");
					this.$emit("fetchData");
				},
        notice(not) {
            this.$Notice[not.type]({
                title: "EMPLOYEE",
                desc: not.text
            });
        }
    }
};
</script>
<style scoped>
.section-divider {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
}
</style>
