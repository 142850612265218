var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"tw-mt-2 overflow-auto tw-max-h-40"},[_c('div',{staticClass:"table-ui-detail"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$t('employee.photo'))+" ")]),_c('th',{staticClass:"text-center",attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t('employee.cardNumber'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('employee.nameEn'))+" ")])])]),_c('tbody',_vm._l((_vm.employees),function(employee,index){return _c('tr',{key:index},[_c('td',{staticClass:"tw-whitespace-nowrap"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                                            src: _vm.photo(employee),
                                            error:
                                                employee.sex == 'Male'
                                                    ? require('@/assets/staff-default-man.png')
                                                    : require('@/assets/staff-default-women.png'),
                                            loading: require('@/assets/Rolling-1s-200px.svg'),
                                            preLoad: 1.3,
                                            attempt: 1
                                        }),expression:"{\n                                            src: photo(employee),\n                                            error:\n                                                employee.sex == 'Male'\n                                                    ? require('@/assets/staff-default-man.png')\n                                                    : require('@/assets/staff-default-women.png'),\n                                            loading: require('@/assets/Rolling-1s-200px.svg'),\n                                            preLoad: 1.3,\n                                            attempt: 1\n                                        }"}],staticClass:"tw-rounded tw-h-8"})]),_c('td',{staticClass:"tw-whitespace-nowrap text-center"},[_vm._v(" "+_vm._s(employee.card_id)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(employee.employee_name_en)+" ")])])}),0)])])])])]),_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-col-label control-label required"},[_vm._v(_vm._s(_vm.$t('employee.toBranch')))]),_c('Select',{class:{
                    'ivu-form-item-error': _vm.errors.has('branch_id')
                },attrs:{"filterable":"","clearable":"","placeholder":_vm.$t('employee.typeToSearch')},model:{value:(_vm.model.branch_id),callback:function ($$v) {_vm.$set(_vm.model, "branch_id", $$v)},expression:"model.branch_id"}},_vm._l((_vm.branches),function(branch,index){return _c('Option',{key:index,attrs:{"value":branch.branch_id,"label":branch.branch_name_en}},[_vm._v(" "+_vm._s(branch.branch_name_en)+" ")])}),1),(_vm.errors.has('branch_id'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first('branch_id'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel')))]),_c('ts-button',{staticClass:"btn btn-primary d-block",attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onMove.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('move')))])],1),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }