<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <div class="tw-mt-2 overflow-auto tw-max-h-40">
                    <div class="table-ui-detail">
                        <table>
                            <thead>
                                <tr>
                                    <th width="10%">
                                        {{ $t('employee.photo') }}
                                    </th>
                                    <th width="25%" class="text-center">
                                        {{ $t('employee.cardNumber') }}
                                    </th>
                                    <th>
                                        {{ $t('employee.nameEn') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(employee, index) in employees"
                                    :key="index"
                                >
                                    <td class="tw-whitespace-nowrap">
                                        <img
                                            class="tw-rounded tw-h-8"
                                            v-lazy="{
                                                src: photo(employee),
                                                error:
                                                    employee.sex == 'Male'
                                                        ? require('@/assets/staff-default-man.png')
                                                        : require('@/assets/staff-default-women.png'),
                                                loading: require('@/assets/Rolling-1s-200px.svg'),
                                                preLoad: 1.3,
                                                attempt: 1
                                            }"
                                        />
                                    </td>
                                    <td
                                        class="tw-whitespace-nowrap text-center"
                                    >
                                        {{ employee.card_id }}
                                    </td>
                                    <td class="tw-whitespace-nowrap">
                                        {{ employee.employee_name_en }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t('employee.toBranch')
                }}</label>
                <Select
                    v-model="model.branch_id"
                    filterable
                    clearable
                    :placeholder="$t('employee.typeToSearch')"
                    :class="{
                        'ivu-form-item-error': errors.has('branch_id')
                    }"
                >
                    <Option
                        v-for="(branch, index) in branches"
                        :value="branch.branch_id"
                        :key="index"
                        :label="branch.branch_name_en"
                    >
                        {{ branch.branch_name_en }}
                    </Option>
                </Select>
                <div class="text-danger" v-if="errors.has('branch_id')">
                    {{ errors.first('branch_id') }}
                </div>
            </div>
        </div>
        <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
            <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
                {{ $t('cancel') }}</ts-button
            >
            <ts-button
                color="primary"
                @click.prevent="onMove"
                :waiting="waiting"
                class="btn btn-primary d-block"
            >
                {{ $t('move') }}</ts-button
            >
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'moveBranch',
    props: ['employees'],
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            model: {
                employee_id: [],
                branch_id: null
            }
        }
    },
    computed: {
        ...mapState('humanResource/employee', ['branches'])
    },
    methods: {
        ...mapActions('humanResource/employee', ['getBranch']),
        photo (record) {
            if (record.photo) {
                return record.photo
            }

            return record.sex == 'Male'
                ? require('@/assets/staff-default-man.png')
                : require('@/assets/staff-default-women.png')
        },
        async fetchResource () {
            this.errors = new Errors()
            this.loading = true
            this.clear()
            await this.getBranch()
            this.model.employee_id = []
            if (this.employees.length > 0) {
                this.employees.map(row => {
                    this.model.employee_id.push(row.employee_id)
                })
            }
            this.loading = false
        },
        onMove () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('humanResource/employee/moveBranch', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                    this.clear()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clear(){
            this.model.employee_id = []
            this.model.branch_id = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'EMPLOYEE',
                desc: not.text
            })
        }
    }
}
</script>
